//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-204:_888,_123,_512,_564,_348,_612,_184,_416;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-204')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-204', "_888,_123,_512,_564,_348,_612,_184,_416");
        }
      }catch (ex) {
        console.error(ex);
      }